import React from 'react';

import ModuleSelector from '@components/sections/ModuleSelector';
import { graphql } from 'gatsby';

type PageProps = {
  data,
}

const Page: React.FC<PageProps> = ({
  data,
}) => {
  const { modules } = data.page;

  return (
    <>
      {
        modules && <ModuleSelector modules={modules} />
      }
    </>
  );
};

export default Page;

export const pageQuery = graphql`
  query QUERY_PAGE($contentful_id: String!) {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }
    seo: contentfulPage(contentful_id: { eq: $contentful_id }) {
      ...PageSeo
    }
    page: contentfulPage(contentful_id: { eq: $contentful_id }) {
      ...PageFull
    }
    scripts: file(relativePath: { eq: "caliberMindSnippet.js" }) {
      publicURL
    }
  }
`;